<script setup>
import { watch } from 'vue';

import { useAuthStore } from '@/stores/auth';
import helpers from '@/helpers';
import MenuLateral from '@/components/MenuLateral.vue';
import Carregamento from '@/components/Carregamento.vue';

const authStore = useAuthStore();

const carregarScriptHeaderFooter = () => {
  const suporte = {
    telefone: '(11) 5555-9252',
    email: 'suporte.crc@registrocivil.org.br',
  };
  let script = document.createElement('script');
  script.setAttribute('type', 'module');
  script.setAttribute('id', 'scriptHeaderFooter');
  script.innerHTML = `
        import {instalarHeaderFooter} from '${
          import.meta.env.VITE_URL_BASE_HEADER_FOOTER
        }/header-footer.min.js';

          instalarHeaderFooter({
              usuario: {
                  nome: '${authStore.usuario?.nome ? authStore.usuario?.nome : ''}',
                  cpf: '${
                    authStore.usuario?.cpf ? helpers.formatarCPF(authStore.usuario.cpf) : ''
                  }',
                  foto: '${authStore.usuario?.fotoPerfilUrl || ''}',
                  grupos: ${JSON.stringify(authStore.usuario?.grupos)},
              },
              urlLogin: '${authStore.urlLogin()}',
              urlLogout: '${authStore.urlLogout()}',
              urlRedirecionamentoSPRC: '${authStore.urlLoginICP()}',
              urlLoginICP: '${authStore.urlLoginICP()}',
              token: '${authStore.getToken()}',
              exibirPerguntasFrequentes: ${true},
              exibirFaleConoscoPeloChat: ${false},
              suporte: '${JSON.stringify(suporte)}',
              exibirAcessoRemoto: ${true},
              exibirWhatsApp: ${false},
              exibirModalCartorios: ${false}
          });
      `;
  document.body.appendChild(script);
};

watch(
  () => authStore.carregando,
  (carregando) => {
    if (!carregando) {
      carregarScriptHeaderFooter();
    }
  }
);
</script>

<template>
  <Carregamento v-if="authStore.carregando === true" />

  <main v-else>
    <header id="header" />
    <div id="conteudo">
      <MenuLateral />
      <RouterView />
    </div>
    <footer id="footer" />
  </main>
</template>

<style lang="scss">
@import url($env_url_base_header_footer + '/header-footer.css');

main {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #containerMenu {
    width: 100%;
  }

  #conteudo {
    width: 100%;
    padding-bottom: 90px;
    position: relative;
    flex: 1;
  }
}
</style>
