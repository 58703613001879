import { defineStore } from 'pinia';
import authService from '@/services/authService';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

let refreshTokenTimeout = null;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    carregando: true,
    token: Cookies.get('application-token')
      ? JSON.parse(atob(Cookies.get('application-token'))).message
      : null,
    usuario: null,
  }),
  actions: {
    login(prompt = 'consent') {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
        prompt,
      });

      location.replace(`${import.meta.env.VITE_API_URL}/login?${params.toString()}`);
    },
    async verifyLogin() {
      await this.verifyAndUpdateToken();
      return this.token;
    },
    async logout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      });

      location.replace(`${import.meta.env.VITE_API_URL}/logout?${params.toString()}`);
    },
    logoutCallback() {
      this.token = null;
      this.usuario = null;
      Cookies.remove('application-token');
      localStorage.removeItem('cartorio');
    },
    updateToken() {
      this.token = this.getToken();
    },
    async verifyAndUpdateToken() {
      try {
        await authService.verifyAndUpdateToken();
        this.token = this.getToken();
        this.refreshToken();
        this.updateToken();
      } catch (e) {
        await this.logoutCallback();
      }
    },
    refreshToken() {
      clearTimeout(refreshTokenTimeout);

      const jwtDecodificado = jwt_decode(this.token);
      const currentDate = new Date();
      const expiresAt = new Date(0);
      expiresAt.setUTCSeconds(jwtDecodificado.expires_at);
      const executeAt = expiresAt - currentDate - 60;

      refreshTokenTimeout = setTimeout(async () => {
        await this.verifyAndUpdateToken();
      }, executeAt);
    },
    urlLogin() {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
      });

      return `${import.meta.env.VITE_API_URL}/login?${params.toString()}`;
    },
    urlLoginICP() {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
        flow: 'icp',
      });

      return `${import.meta.env.VITE_API_URL}/login?${params.toString()}`;
    },
    urlLogout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      });

      return `${import.meta.env.VITE_API_URL}/logout?${params.toString()}`;
    },
    getToken() {
      const token = Cookies.get('application-token');
      return token && JSON.parse(atob(token)).message;
    },
  },
});
